<template>
	<modal-wrapper @close="$emit('close')" size="small">
		<div class="modal-body">
			<h2 class="settings-headline">
				{{ new_user ? 'Benutzer hinzufügen' : 'Benutzer bearbeiten' }}
			</h2>
			<section class="user-edit-form">
				<form @submit.prevent="updateUser">
					<label for="name">Name</label>
					<input
						type="text"
						id="name"
						name="name"
						v-model="$v.edited_user.name.$model"
					/>
					<span
						class="has-error"
						v-if="$v.edited_user.name.$dirty && !$v.edited_user.name.required"
						>Es muss ein Name angegeben sein!</span
					>
					<label for="email">E-Mail Adresse</label>
					<input
						type="email"
						id="email"
						name="email"
						v-model="$v.edited_user.email.$model"
					/>
					<span
						class="has-error"
						v-if="$v.edited_user.email.$dirty && !$v.edited_user.email.required"
						>Es muss eine E-Mail Adresse angegeben sein!</span
					>
					<hr />
					<label for="role">Rolle</label>
					<select v-model="edited_user.role" id="role">
						<option v-for="role in roles" :key="role.UUID" :value="role.UUID">
							{{ role.friendly_name }}
						</option>
					</select>

					<hr />
					<small v-if="!new_user"
						>Wenn Passwort nicht gefüllt, bleibt es ungeändert</small
					>
					<label for="password">Passwort</label>
					<input
						type="password"
						id="password"
						name="password"
						v-model="$v.edited_user.password.$model"
					/>
					<span class="has-error" v-if="!$v.edited_user.password.minLength"
						>Das neue Passwort muss mindestens 8 Zeichen lang sein!</span
					>
					<label for="password-repeat">Passwort wiederholen</label>
					<input
						type="password"
						id="password-repeat"
						name="password-repeat"
						v-model="$v.edited_user.password_repeat.$model"
					/>
					<span
						class="has-error"
						v-if="
							$v.edited_user.password_repeat.$dirty &&
							!$v.edited_user.password_repeat.sameAsNewPassword
						"
						>Die Passwörter stimmen nicht überein!</span
					>
					<button type="submit" class="button w-button">
						{{ new_user ? 'Benutzer hinzufügen' : 'Benutzer ändern' }}
					</button>
					<span class="has-error" v-if="error">
						Es gab einen Fehler, bitte versuchen Sie es erneut.
					</span>
				</form>
			</section>
		</div>
	</modal-wrapper>
</template>

<script>
import {
	required,
	sameAs,
	minLength,
	requiredIf
} from 'vuelidate/lib/validators';
import cryptoMixin from '@/mixins/crypto.mixin';
export default {
	props: ['user', 'new_user'],
	components: {
		modalWrapper: () => import('../modal-wrapper.vue')
	},
	mixins: [cryptoMixin],
	data() {
		return {
			edited_user: {
				name: '',
				email: '',
				password: '',
				password_repeat: '',
				role: ''
			},
			error: false,
			is_new: false,
			roles: []
		};
	},
	validations: {
		edited_user: {
			name: {
				required
			},
			email: {
				required
			},
			password: {
				required: requiredIf(function () {
					return this.is_new;
				}),
				minLength: minLength(8)
			},
			password_repeat: {
				required: requiredIf(function () {
					return this.edited_user.password !== '';
				}),
				sameAsNewPassword: sameAs(function () {
					return this.edited_user.password;
				})
			}
		}
	},
	mounted() {
		if (this.new_user) {
			this.is_new = true;
		} else {
			this.edited_user = { ...this.edited_user, ...this.user };
			this.edited_user.role = this.user.role.UUID;
		}
		this.getRoles();
	},
	methods: {
		async getRoles() {
			const rolesResponse = await this.$api.post(
				'auth/get_roles',
				{
					mode: this.$store.getters.getAppMode
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			if (rolesResponse.status === 200) {
				this.roles = rolesResponse.data;
			}
		},

		async updateUser() {
			this.$v.edited_user.$touch();
			this.error = false;
			if (!this.$v.$anyError) {
				if (this.edited_user.password !== '') {
					const encryptedKey = await this.encryptPrivateKeyCall(
						this.edited_user.password
					);
					this.edited_user.private_key = encryptedKey;
				}
				try {
					const updateResponse = await this.$api.post(
						this.is_new ? '/auth/new_user' : '/auth/update_user',
						{
							user: this.edited_user
						},
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getUserToken}`
							}
						}
					);

					if (updateResponse.status === 200) {
						this.$emit('reload');
					} else {
						this.error = true;
					}
				} catch (e) {
					this.error = true;
				}
			}
		},
		async encryptPrivateKeyCall(newPassword) {
			const priv = await this.$store.getters.getPrivateKey;
			const encrypted = await this.encryptPrivateKey(newPassword, priv);
			return encrypted;
		}
	}
};
</script>

<style lang="scss">
.modal-body {
	display: block;
	padding: 1rem;
	display: grid;
	grid-template-rows: 1fr auto;
	.user-edit-form {
		width: 75%;
		padding: 1rem 0 0 1rem;
		form {
			gap: 1rem;
			button {
				width: 50%;
			}
			.has-error {
				text-align: left;
			}
		}
	}
}
</style>
